* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App {
    background-color: #f2f2f2;
    display: flex;
    flex-direction: column;
    /* width: 100vw; */
}

html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}