footer {
  background-color: rgba(0, 0, 0, 0.849);

  color: #FFF;
  text-align: center;
  font-family: fira code;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoFooter {
  width: 16vw;
}

div h2 {
  margin-right: 5rem;
  text-align: end;
  font-family: 'Ibarra Real Nova';
  font-weight: 600;
  color: #e2e7e6c4;
}