.nav {
  background-color: rgba(20, 20, 20, 0.959);
  box-shadow: -5px 12px rgba(32, 32, 32, 0.356);
  width: 100%;

  position: fixed;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inputMenu {
  display: none;
}

.container-link {
  list-style: none;
  width: 60vw;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.menu-link {
  all: unset;
  font-family: 'Ibarra Real Nova';
  font-weight: 700;
  font-size: 2.5rem;
  color: #e2e7e6c4;
  cursor: pointer;
}

.menu-link:hover {
  color: #949797c4;
  font-weight: 700;
  font-size: 2.5rem;
}

.logo {
  height: 14vh;
  display: flex;
  align-items: center;
}

@media (max-width: 940px) {
  .nav {
    background-color: rgba(20, 20, 20, 0.959);
    box-shadow: -5px 12px rgba(32, 32, 32, 0.356);
    width: 100%;

    position: fixed;
  }

  .row-menu {
    width: 100vw;
    height: 14vh;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 30px;
    margin-right: 20px;
  }

  /* ANIMAÇÃO HAMBURGUER */
  *,
  *:before,
  *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  .menu {
    background: rgba(65, 64, 64, 0.849);
    width: 5vw;
    height: 6vh;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    border-radius: 10px;
    box-shadow: 0 0 0 0 rgba(65, 64, 64, 0.849), 0 0 0 0 rgba(65, 64, 64, 0.849);
    transition: box-shadow .43s cubic-bezier(.29, .5, .27, .5);
  }

  .menu:hover {
    box-shadow: 0 0 0 8px rgba(65, 64, 64, 0.849), 0 0 0 8px rgba(65, 64, 64, 0.849);
  }

  input:checked~label .menu {
    box-shadow: 0 0 0 22vw rgba(65, 64, 64, 0.849), 0 0 0 22vw rgba(65, 64, 64, 0.849);
  }

  input:checked~ul {
    opacity: 1;
  }

  .hamburguer {
    position: relative;
    display: block;
    background-color: rgba(236, 228, 228, 0.733);
    width: 3vw;
    height: 2px;
    transition: .5s ease-in-out;
    /* margin-left: 15px; */
    /* top: 30px; */
  }

  .hamburguer:before,
  .hamburguer:after {
    background-color: rgba(236, 228, 228, 0.733);
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: .5s ease-in-out;
  }

  .hamburguer:before {
    top: -10px;
  }

  .hamburguer:after {
    bottom: -10px;
  }

  input {
    display: none;
  }

  input:checked~label .hamburguer {
    transform: rotate(45deg);
  }

  input:checked~label .hamburguer:before {
    transform: rotate(90deg);
    top: 0;
  }

  input:checked~label .hamburguer:after {
    transform: rotate(90deg);
    bottom: 0;
  }

  .container-link {
    list-style: none;
    position: absolute;
    transform: translate(-60%, -20%);
    opacity: 0;
    transition: box-shadow 1s 1s cubic-bezier(1, 1.03, 0, 2.02);
    margin-top: 24rem;
    margin-left: 10rem;
    width: 15rem;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    list-style: none;
  }
}

@media (max-width: 700px) {
  input:checked~label .menu {
    box-shadow: 0 0 0 29vw rgba(65, 64, 64, 0.849), 0 0 0 31vw rgba(65, 64, 64, 0.849);
  }
}

@media (max-width: 490px) {
  input:checked~label .menu {
    box-shadow: 0 0 0 35vw rgba(65, 64, 64, 0.849), 0 0 0 36vw rgba(65, 64, 64, 0.849);
  }

  .container-link {
    list-style: none;
    position: absolute;
    transform: translate(-60%, -20%);
    opacity: 0;
    transition: box-shadow 1s 1s cubic-bezier(1, 1.03, 0, 2.02);
    margin-top: 21rem;
    margin-left: 8rem;
    width: 15rem;
    /* height: 50vh; */
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    list-style: none;
  }

  .menu {
    background: rgba(65, 64, 64, 0.849);
    width: 8vw;
    height: 6vh;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 0 0 0 rgba(65, 64, 64, 0.849), 0 0 0 0 rgba(65, 64, 64, 0.849);
    transition: box-shadow .43s cubic-bezier(.29, .5, .27, .5);
  }
}

@media (max-width: 370px) {
  input:checked~label .menu {
    box-shadow: 0 0 0 38vw rgba(65, 64, 64, 0.849), 0 0 0 39vw rgba(65, 64, 64, 0.849);
  }
}