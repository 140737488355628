.containerknowledges {
   margin-top: 20vh;

}

.firstSeason {
   display: flex;
   justify-content: space-around;
}

.titleSkill {
   padding-bottom: 20px;
   font-family: 'Ibarra Real Nova';
   font-weight: 700;
   font-size: 40px;
   color: #33323D;
   border-bottom: 1px solid #33323dd0;
}

.leftSide {
   width: 50vw;
   border-right: 3px solid #33323dd0;
}

.leftSide p {
   text-align: justify;
   font-weight: 400;
   margin-top: 10px;
   padding: 20px 25px 20px 10px;
   font-family: 'Public Sans';
   font-weight: 400;
   font-size: 20px;
   line-height: 30px;
   color: #33323D;
}

.nameSkill {
   width: 20vw;
   text-align: justify;
   font-weight: 700;
   padding: 0px 25px 20px 25px;
   font-family: 'Public Sans';
   font-size: 20px;
   line-height: 30px;
   color: #33323D;
   justify-self: center;
}

.nameSkills {
   /* width: 10vw; */
   text-align: justify;
   font-weight: 700;
   padding: 0px 25px 20px 25px;
   font-family: 'Public Sans';
   font-size: 25px;
   line-height: 30px;
   color: #33323D;
   justify-self: center;
}

.hardSkills {
   display: flex;
   flex-direction: column;
   padding-top: 20px;
   margin-right: 2rem;
}

.boxSkill {
   display: flex;
   justify-content: space-between;
}

.languages {
   width: 45vw;
}

.boxLevel {
   width: 25vw;
   height: 8px;
   border-radius: 50px;
   border: 1px solid blue;
}

.boxLevelSkills {
   width: 38vw;
   height: 8px;
   border-radius: 50px;
   border: 1px solid blue;
}

.sizeLevel {
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding-top: 5px;
}

.sizeLevel span {
   font-family: 'Public Sans';
   font-size: 1rem;
   line-height: 30px;
   color: #33323D;
}

.levelbar1 {
   width: 70%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.levelbar2 {
   width: 70%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.levelbar3 {
   width: 50%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.levelbar4 {
   width: 40%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.levelbar5 {
   width: 55%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.levelbar6 {
   width: 55%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.levelbar7 {
   width: 25%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.boxlanguage {
   /* width: 50vw; */
   height: 35vh;
   justify-content: space-between;
   align-items: center;
   padding-bottom: 50px;
   margin-bottom: 10px 20px 20px 25px;
   display: flex;
   border-bottom: 1px solid #33323dd0;
}

.last {
   /* width: 50vw; */
   height: 35vh;
   justify-content: space-between;
   align-items: center;
   padding-bottom: 50px;
   margin-bottom: 10px 20px 20px 25px;
   display: flex;
}

.containerLevel {
   width: 35vw;
   height: 20vh;
   display: flex;
   flex-direction: column;
   justify-content: space-around;
}

.nametitle {
   margin: 25px 0 5px 0;
   font-family: 'Public Sans';
   font-weight: 700;
   font-size: 2rem;
   line-height: 30px;
   color: #33323D;
}

.speakbar1 {
   width: 60%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.listenbar1 {
   width: 65%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.writebar1 {
   width: 60%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.speakbar2 {
   width: 35%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.listenbar2 {
   width: 55%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;

}

.writebar2 {
   width: 20%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.speakbar3 {
   width: 100%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.listenbar3 {
   width: 100%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.writebar3 {
   width: 100%;
   height: 6px;
   background-color: blue;
   border-radius: 50px;
}

.softSkills {
   font-weight: 400;
   border-top: 3px solid #33323dd0;
   display: flex;
   margin-top: 10px;
   padding: 20px 25px 20px 10px;
   font-family: 'Public Sans';
   font-weight: 400;
   font-size: 20px;
   line-height: 30px;
   color: #33323D;
}

.courses {
   border-top: 3px solid #33323dd0;
}

.courses p {
   width: 100%;
   margin-bottom: 30px;
   text-align: center;
   font-family: 'Public Sans';
   font-weight: 700;
   font-size: 30px;
   line-height: 30px;
   color: #33323D;
}

.courses ul {
   width: 50vw;
   margin-bottom: 10px;
}

.courses {
   font-weight: 400;
   margin-top: 10px;
   padding: 20px 25px 20px 10px;
   font-family: 'Public Sans';
   font-weight: 400;
   font-size: 20px;
   line-height: 30px;
   color: #33323D;
}

.softSkills p {
   width: 90vw;
   text-align: justify;
   font-weight: 400;
   margin-top: 10px;
   padding: 20px 25px 20px 10px;
   font-family: 'Public Sans';
   font-weight: 400;
   font-size: 20px;
   line-height: 30px;
   color: #33323D;
}

.list {
   width: 45vw;
   height: 60vh;
   display: flex;
   flex-direction: column;
   align-self: center;
   padding: 0 20px 0 0px;
   justify-content: space-evenly;
   margin: 3vh 5vh 4vh 0;
}

.listSoft p {
   /* width: 100vw; */
   margin-bottom: 30px;
   text-align: center;
   font-family: 'Public Sans';
   font-weight: 700;
   font-size: 30px;
   line-height: 30px;
   color: #33323D;
}

.list li {
   margin-left: 3rem;
   width: 100%;
}

.coursesList {
   display: flex;
}

.imgcourses {
   width: 40vw;
}

.skillsList {
   display: flex;
   margin-left: 5vw;
}

.boxCertified {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 5rem;
}

.certified {
   all: unset;
   margin: 0;
   padding: 0;
   box-sizing: border-box;
   cursor: pointer;
   border: 1px solid #33323d;
   width: 148px;
   height: 48px;
   text-align: center;

   font-family: 'Public Sans';
   font-weight: 400;
   font-size: 12px;
   line-height: 14px;
   letter-spacing: 2px;
   text-transform: uppercase;
}

.certified:hover {
   background-color: #33323D;
   color: #fff;
}

@media (max-width: 900px) {
   .list {
      padding: 0;
      width: 80%;
      margin: 0;
   }
}

@media (max-width: 770px) {
   .firstSeason {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
   }

   .leftSide {
      width: 100%;
      border: none;
   }

   .boxlanguage {
      /* width: 100vw; */
      /* height: 35vh; */
      justify-content: space-between;
      align-items: center;
      padding-bottom: 80px;
      margin-bottom: 10px 20px 20px 25px;
      display: flex;
      border-bottom: 1px solid #33323dd0;
   }

   .boxLevelSkills {
      margin-right: 3rem;
   }

   .boxLevel {
      width: 100%;
   }

   .containerLevel {
      width: 50%;
      margin-right: 2rem;
      margin-bottom: 1rem;
      margin-top: 4rem;
   }

   .nameSkills {
      /* width: 10vw; */
      text-align: justify;
      font-weight: 700;
      padding: 0 2rem 20px 1rem;
      font-family: 'Public Sans';
      font-size: 25px;
      line-height: 30px;
      color: #33323D;
      justify-self: center;
   }

   .languages {
      width: 100%;
      height: 100%;
   }

   .last {
      width: 100%;
   }

   .list {
      padding: 0;
      width: 80%;
      margin: 4rem;
      width: 100%;
   }

   .listSoft {
      padding: 0;
      width: 70%;
      margin: 0;
   }

   .list li {
      padding: 0;
      width: 80%;
      margin: 4rem;
   }

   .coursesList {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }

   .middleList {
      flex-direction: column-reverse;
   }

   .imgcourses {
      width: 70vw;
   }

   .softSkills {
      font-family: 'Public Sans';
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #33323D;
      display: flex;
   }

   .skillsList {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;
      margin-left: 5vw;
   }

   .skillsList li {
      margin-bottom: 3rem;
      width: 85vw;
   }
}