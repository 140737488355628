.body {
  /* width: 100vw; */
  margin-top: 20vh;
  display: flex;
  flex-direction: column;

}

.face {
  width: 40vw;
  height: 60vh;
  /* margin-left: 50px; */
  border-radius: 40px;
}

.aside {
  text-align: justify;
  margin-left: 5rem;
  display: flex;
  /* align-items: center; */
  flex-direction: row;
  /* flex-wrap: wrap; */
}

.meAbout {
  font-family: 'Ibarra Real Nova';
  font-weight: 700;
  font-size: 5rem;
  color: #33323D;
  text-align: center;
}

.text-meAbout {
  font-family: 'Public Sans';
  font-weight: 400;
  font-size: 2rem;
  line-height: 30px;
  text-align: justify;
  color: #33323D;
  padding-left: 3rem;
  margin-right: 3rem;
  margin-top: 5rem;
  margin-bottom: 5rem;
}


.text-me2 {
  font-family: 'Public Sans';
  font-weight: 400;
  font-size: 2rem;
  line-height: 30px;
  color: #33323D;
  width: 60%;
  text-align: justify;
  padding: 3rem;
}

.cidade {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 10vh;
  margin-bottom: 10vh;
}

.cwb {
  width: 35vw;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 40px;
}

.contact1 {
  border-top: 1px solid #33323d42;
  border-bottom: 1px solid #33323d42;
  height: 20vh;
  width: 90%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
}

.size-box {
  width: 50%;
  text-align: left;
  padding-top: 8vh;
  padding-bottom: 9vh;
}

.boxPort {
  width: 100%;
  padding: 0 0 8vh 10vw;
}

.button-pt {
  all: unset;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  border: 1px solid #33323d;
  width: 148px;
  height: 48px;
  text-align: center;

  font-family: 'Public Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button-text {
  all: unset;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  width: 148px;
  height: 48px;
  text-align: center;

  font-family: 'Public Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button-pt:hover {
  background-color: #33323D;
  color: #fff;
}

@media (max-width: 900px) {
  .text-meAbout1 {
    width: 85vw;
    text-align: justify;
    font-family: 'Public Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #33323D;
    margin-left: -40vw;
    margin-right: 3rem;
  }

  .cwb {
    width: 45vw;
    height: 45vh;
    margin-right: 2rem;
  }
}

@media (max-width: 700px) {
  .aside {
    text-align: justify;
    display: flex;
    /* align-items: center; */
    margin: 0 2rem 0 2rem;
    flex-direction: column;
    /* flex-wrap: wrap; */
  }

  .face {
    width: 60vw;
    height: 40vh;
    /* margin-left: 50px; */
    border-radius: 40px;
    justify-self: center;
    align-self: center;
  }


  .text-meAbout {
    width: 100%;
    font-family: 'Public Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #33323D;
    margin-top: 4rem;
    padding: 0;
    margin-right: 4rem;
  }

  .text-meAbout1 {
    width: 100%;
    font-family: 'Public Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #33323D;
    margin-left: 0;
    padding: 0;
    margin-right: 4rem;
  }

  .cidade {
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }

  .text-me2 {
    width: 100%;
    text-align: justify;
    padding: 3rem;
  }

  .cwb {
    width: 45vh;
    border-radius: 40px;
    align-self: center;
  }

  .size-box {
    width: 59%;
    text-align: left;
    line-height: 30px;
    padding-top: 5vh;
  }

  .meAbout {
    font-family: 'Ibarra Real Nova';
    font-weight: 700;
    font-size: 2rem;
    color: #33323D;
    margin-bottom: 5vh;
  }
  .contact1{
    margin-right: 2rem;
    margin-left: 2rem;
  }
}