.containerProject {
    margin-top: 25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f2f2f2;
}

.titleProjects {
    font-family: 'Ibarra Real Nova';
    font-weight: 700;
    font-size: 40px;
    color: #33323D;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lineRow {
    width: 70vw;
    margin-bottom: 6vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-bottom: 6vh;
    border-bottom: 1px solid #33323d34;
}

.arrows {
    opacity: 0.7;
    cursor: pointer;
    width: 30px;
}

.boxProject {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6vh;
    border-bottom: 1px solid #33323d34;
}

.picture {
    width: 45vw;
    /* min-height: 50vh; */
}

.image {
    width: 100%;
    height: 80%;
    justify-self: center;
    box-shadow: 12px 11px 21px -4px rgba(0, 0, 0, 0.81);
    -webkit-box-shadow: 12px 11px 21px -4px rgba(0, 0, 0, 0.81);
    -moz-box-shadow: 12px 11px 21px -4px rgba(0, 0, 0, 0.81);
}

.description {
    width: 55vw;
    min-height: 50vh;
    font-family: 'Public Sans';
    font-weight: 400;
    font-size: 16px;
    text-align: justify;
    line-height: 30px;
    color: #33323D;
    padding: 0vh 4vh 4vh 4vh;
    margin: 3vh 5vh 4vh 5vh;
}

.description p {
    margin: 3vh 0 4vh 0;
}

.description a {
    all: unset;
}

@media (max-width: 450px) {
    .lineRow {
        width: 100%;
        margin-bottom: 6vh;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 6vh;
        border-bottom: 1px solid #33323d34;
    }

    .arrows {
        margin: 0 1rem 0 1rem;
    }

    .titleProjects {
        font-size: 3rem;
    }

    .boxProject {
        flex-direction: column;
    }

    .reverse {
        display: flex;
        flex-direction: column-reverse;
    }

    .picture {
        width: 100%;
    }

    .description {
        width: 100%;
    }
}