.icons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;

  /* width: 100%; */
  height: 20vh;
  background-color: rgba(0, 0, 0, 0.329);
  position: relative;
  overflow: hidden;
}

.imageIcon {
  animation: image-animation 20s infinite linear;
  position: relative;
}

@keyframes image-animation {
  from {
    right: -100vw;
  }

  to {
    right: 100%
  }
}

.html {
  width: 22vw;
  height: 15vh;
}

.css {
  width: 22vw;
  height: 15vh;
}

.js {
  width: 22vw;
  height: 15vh;
}

.node {
  width: 15vw;
  height: 15vh;
}

.react {
  width: 20vw;
  height: 15vh;
  margin-left: 80px;
}

.sql {
  width: 7vw;
  height: 15vh;
}

.sass {
  width: 15vw;
  height: 15vh;
  margin-left: 80px;
}

@media (max-width: 600px) {

  @keyframes image-animation {
    from {
      right: -150vw;
    }

    to {
      right: 150%
    }
  }

  .node {
    width: 25vw;
    height: 10vh;
  }

  .react {
    width: 23vw;
    height: 15vh;
  }

  .sql {
    width: 10vw;
    height: 15vh;
  }

  .sass {
    width: 20vw;
    height: 15vh;
  }
}

@media (max-width: 450px) {
  .node {
    width: 200px;
    height: 10vh;
    margin-right: 2rem;
  }

  .react {
    width: 400px;
    height: 15vh;
  }

  .sql {
    width: 200px;
    height: 15vh;
  }

  .sass {
    width: 400px;
    height: 15vh;
  }
}