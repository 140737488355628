.contact {
  background-color: #f2f2f2;
}

.containerContact {
  margin-top: 25vh;
  width: 80vw;
  display: flex;
  align-self: center;
  flex-direction: column;
}

.sectionContact {
  padding-top: 25px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #33323dd0;
}

.title {
  width: 40%;
  font-family: 'Ibarra Real Nova';
  font-weight: 700;
  font-size: 40px;
  color: #33323D;
}

section p {
  width: 60%;
  align-self: center;
  font-family: 'Public Sans';
  font-weight: 400;
  font-size: 16px;
  text-align: justify;
  line-height: 30px;
  color: #33323D;
}

.division {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 3rem 0 3rem 0;
}

.gitIcon {
  width: 6rem;
  margin-left: 4%;
}

.linkIcon {
  width: 6rem;
  margin-left: 38%;
}

.titleForm {
  display: flex;
  border-top: 1px solid #33323dd0;
  border-bottom: 1px solid #33323dd0;
  padding-top: 40px;
  margin-bottom: 30px;
}

.form label {
  display: flex;
  flex-direction: column;
  width: 50vw;
  font-family: 'Public Sans';
  font-weight: 700;
  font-size: 13px;
  line-height: 30px;
  color: #33323D;

  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 2%;
}

label input {
  all: unset;
  background-color: #33323d27;
  margin-top: 1%;
  color: #203A4C;
  padding: 2px 2px 2px 6px;
  font-family: 'Public Sans';
  font-weight: 400;
  font-size: 13px;
}

.inputMsg {
  all: unset;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 15vh;
  /* align-items: start; */
  word-wrap: break-word;
  overflow-wrap: break-word;
  background-color: #33323d27;
  color: #203A4C;
  font-family: 'Public Sans';
  font-weight: 400;
  font-size: 13px;
  padding: 5px 2px 2px 6px;
}

.buttonSend {
  all: unset;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
  background-color:
    #203A4C;

  font-family: 'Public Sans';
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;

  color: #FFFFFF;
  width: 200px;
  height: 48px;
  margin-bottom: 30px;
  margin-top: 2%;
}

.buttonSend:hover {
  background-color: #5FB4A2;
}

.errorSpan {
  font-size: 1.5rem;
  color: red;
  margin-top: .5rem;
}

@media (max-width: 450px) {
  .title {
    font-size: 2.5rem;
    width: 60%;
  }

  .containerContact {
    width: 90vw;
  }

  .division {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
  }

  .sectionContact {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  section p {
    width: 100%;
    margin: 2rem 0 2rem 0;
  }

  .linkIcon {
    margin: 0;
  }

  .titleForm {
    flex-direction: column;
  }

  .form label {
    width: 100%;
  }

}