  .Home {
    background-image: url('../../images_all/home/planodeFundo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    display: flex;
    flex-direction: column;
  }

  .intro_face {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .capa {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .face-photo {
    width: 24vw;
    height: 53vh;
    border-radius: 50%;
    margin-top: 7rem;
    margin-bottom: 2rem;
  }

  .wellcome {
    color: #b0aab4;
    font-family: fira code;
    font-size: 3rem;
    line-height: 5rem;
    text-align: center;
    margin: 0px auto;
    border-right: 2px solid rgba(255, 255, 255, 0.75);
    white-space: nowrap;
    overflow: hidden;
    display: flex;
  }

  .tag {
    color: #c90064;
    font-family: fira code;
    font-size: 3rem;
    line-height: 5rem;
    width: max-content;
    margin: 0;
  }

  .lt-start {
    color: rgba(226, 231, 230, 0.769);
  }

  .lt-end {
    color: rgba(226, 231, 230, 0.769);
  }

  .typing-animation {
    animation: blinkCursor 800ms steps(20) infinite normal, typing 4s steps(20) 1s normal both;
  }

  @keyframes typing {
    from {
      width: 0;
    }

    to {
      width: 55rem;
    }
  }

  @keyframes blinkCursor {
    from {
      border-right-color: rgb(255, 255, 255);
    }

    to {
      border-right-color: transparent;
    }
  }

  .main {
    background-color: #f2f2f2;
    /* width: 100vw; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .presents {
    padding: 0px 30px 20px 30px;
    margin-top: 5rem;
    font-family: 'Ibarra Real Nova';
    font-weight: 700;
    font-size: 4rem;
    color: #8700FF;
    text-align: center;
  }

  .me {
    text-align: justify;
    font-weight: 400;
    /* color: rgba(226, 231, 230, 0.769); */
    padding: 0px 25px 20px 0;
    font-family: 'Public Sans';
    font-weight: 400;
    font-size: 1.7rem;
    line-height: 3rem;
    color: #33323D;
  }

  .presents-2 {
    color: #00ff27;
    text-align: center;
    font-family: indie Flower;
    font-size: 3rem;
    margin-bottom: 5rem;
  }

  @media (max-width: 1000px) {
    .face-photo {
      width: 30vw;
      height: 50vh;
      border-radius: 50%;
      margin-top: 5rem;
      margin-bottom: 2rem;
    }
  }

  @media (max-width: 860px) {
    .face-photo {
      width: 30vw;
      height: 50vh;
      border-radius: 50%;
      margin-top: 5em;
      margin-bottom: 2em;
    }
  }

  @media (max-width: 700px) {
    .face-photo {
      width: 40vw;
      height: 50vh;
      border-radius: 50%;
      margin-top: 5em;
      margin-bottom: 2em;
    }
  }

  @media (max-width: 500px) {
    .face-photo {
      width: 50vw;
      height: 45vh;
      border-radius: 50%;
      margin-top: 5em;
      margin-bottom: 2em;
    }
  }

  @media (max-width: 650px) {
    .wellcome {
      color: #b0aab4;
      font-family: fira code;
      font-size: 1.5rem;
      line-height: 5rem;
      text-align: center;
      margin: 0;
      border-right: 2px solid rgba(255, 255, 255, 0.75);
      white-space: nowrap;
      overflow: hidden;
      display: flex;
      justify-content: center;
    }

    .tag {
      color: #c90064;
      font-family: fira code;
      font-size: 1.5rem;
      /* line-height: 5rem; */
      width: max-content;
      margin: 0;
    }

    .lt-start {
      color: rgba(226, 231, 230, 0.769);
    }

    .lt-end {
      color: rgba(226, 231, 230, 0.769);
    }

    .face-photo {
      width: 50vw;
      height: 40vh;
      border-radius: 50%;
      margin-top: 5em;
      margin-bottom: 2em;
    }
  }